;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"3fb7cfabae2b4ad620eb28d68cb57975eff09292"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const env = process.env.NEXT_PUBLIC_APP_ENV || "development";

Sentry.init({
  dsn: env !== "development" ? process.env.NEXT_PUBLIC_SENTRY_HOST : "",
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [
        "localhost",
        "https://rimo-backend.rimo.app",
        "https://rimo-backend.rimo-stg.app",
        "https://collab-editor-server.rimo.app",
        "https://collab-editor-server.rimo-stg.app",
        /^https:\/\/[^.]+\.asp\.rimo\.app\//,
        /^https:\/\/[^.]+\.asp\.rimo-stg\.app\//,
      ],
    }),
    Sentry.dedupeIntegration(),
  ],
  environment: env,
  release: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
  ignoreErrors: [
    // this error may be caused by MSFT crawler
    // Ref: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/25
    /.*Object Not Found Matching Id.*/,
  ],
  tracesSampleRate: process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 1.0,
  // maxBreadcrumbs: 10 is needed to avoid 413 (Request Entity Too Large)
  // if 413 error still occurs, try to implement 'before-breadcrumb' callback
  // https://docs.sentry.io/error-reporting/configuration/filtering/?platform=javascript#before-breadcrumb
  maxBreadcrumbs: 10,
});
